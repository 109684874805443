.btn {
    border: 1px solid transparent;
    color: var(--defaultTextColor);
    font-size: var(--defaultFontSize);
    line-height: var(--defaultLineHeight);
    background-color: transparent;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    text-align: center;
}
.btn:hover {
    opacity: .8;
}
.btn--default {
    background-color: var(--defaultTextColor); 
    border-color: var(--defaultTextColor);
    color: var(--white);   
}
.btn--default:hover {
    background-color: transparent;  
    color: var(--defaultTextColor);  
}
.btn--primary {
    background-color: var(--primary); 
    border-color: var(--primary);
    color: var(--white);    
}
.btn--primary:hover {
    background-color: transparent;  
    color: var(--primary);  
}
.btn--secondary {
    background-color: var(--secondary); 
    border-color: var(--secondary); 
    color: var(--white);  
}
.btn--secondary:hover {
    background-color: transparent;  
    color: var(--secondary);  
}
.btn--warning {
    background-color: var(--warning);  
    border-color: var(--warning);  
}
.btn--warning:hover {
    background-color: transparent; 
}
.btn--danger {
    background-color: var(--danger); 
    border-color: var(--danger);
    color: var(--white);    
}
.btn--danger:hover {
    background-color: transparent;  
    color: var(--danger);  
}
.btn--outline-default {
    border: 1px solid var(--defaultTextColor);
}
.btn--outline-default:hover {
    background-color: var(--defaultTextColor);
    color: var(--white);
}
.btn--outline-danger {
    border: 1px solid var(--danger);
    color: var(--danger);
}
.btn--outline-danger:hover {
    background-color: var(--danger);
    color: var(--white);
}
.btn--outline-warning {
    border: 1px solid var(--warning);
    color: var(--defaultTextColor);
}
.btn--outline-warning:hover {
    background-color: var(--warning);    
}
.btn--outline-secondary {
    border: 1px solid var(--secondary);
    color: var(--secondary);
}
.btn--outline-secondary:hover {
    background-color: var(--secondary);
    color: var(--white);
}
.btn--outline-primary {
    border: 1px solid var(--primary);
    color: var(--primary);
}
.btn--outline-primary:hover {
    background-color: var(--primary);
    color: var(--white);
}
.btn.disabled {
    pointer-events: none;
    opacity: .65;
}
.btn--lg {
    padding: 10px 16px;
}

.btn--md {
    padding: 6px 10px;
}