.input {
    background: #07161c;
    border: 1px solid #324457;
    border-radius: calc(300px * var(--scale));
    padding: calc(13px * var(--scale)) calc(20px * var(--scale));
    color: var(--white);
    font-weight: 400;
    font-size: calc(20px * var(--scale));
    width: 100%;
}
.input:focus {
    border-color: var(--secondary);
}
.input-label {
    font-size: calc(14px * var(--scale));
    line-height: calc(16px * var(--scale));
    color: #7c8da0;
    margin: 0 calc(20px * var(--scale)) calc(6px * var(--scale)) calc(20px * var(--scale));
}

.input-label::before {
    content: "*";
}
.input-wrapper {
    width: 100%;
}
.input-wrapper:focus-within label,
.input-wrapper:focus-within .toggle-password {
    color: var(--secondary);
    transition: all 0.5s ease;
}
.input-wrapper:focus-within .toggle-password svg path {
    fill: var(--secondary);
}
.password-wrapper {
    position: relative;
}

.password-wrapper .toggle-password {
    position: absolute;
    top: 50%;
    right: calc(21px * var(--scale));
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    color: #324457;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sign-layout_container {
    display: flex;
    height: 100vh;
}

.signIn-left_container,
.signIn-right_container {
    flex-grow: 1;
    height: 100%;
    width: 50%;
}

.auth-top_link{
    display: flex;
    justify-content: flex-end;
    font-weight: 700;
    width: 100%;
    font-size: calc(18px * var(--scale));
    line-height: calc(22px * var(--scale));
    color: #00E0D1;
    padding: calc(20px * var(--scale)) calc(70px * var(--scale));
}

.sign-footer_mobile{
    display: none;
    width: 100%;
    padding: calc(20px * var(--scale)) calc(50px * var(--scale));
}

.footer-mobile_link{
    font-size: calc(18px * var(--scale));
    line-height: calc(22px * var(--scale));
    color: #00E0D1;
}

.signIn-left_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.signIn-right_container {
    background-color: #081921;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signin-form_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: calc(400px * var(--scale));
    height: 100%;
}

.heading {
    font-family: var(--fontFamily);
    font-style: normal;
    font-weight: 700;
    font-size: calc(34px * var(--scale));
    line-height: calc(40px * var(--scale));
}
.heading.heading-h4 {
    font-size: calc(16px * var(--scale));
    line-height: calc(24px * var(--scale));
}

.subheading {
    font-weight: 400;
    font-size: calc(16px * var(--scale));
    line-height: calc(18px * var(--scale));
    color: #7c8da0;
}

.spacer-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    gap: calc(14px * var(--scale));
}

.spacer-label {
    color: var(--secondary);
}

.google-signin_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: calc(15px * var(--scale)) calc(16px * var(--scale));
    width: 100%;
    background-color: #07161c;
    border: 1px solid #324457;
    border-radius: calc(300px * var(--scale));
    color: #7c8da0;
    font-size: calc(16px * var(--scale));
}

.agreement-paragpaph {
    font-size: calc(12px * var(--scale));
    line-height: calc(16px * var(--scale));
    text-align: center;
    color: #7c8da0;
}

.link {
    color: var(--secondary);
}

.signin-form_container .btn:disabled,
button[disabled] {
    background: #324457;
    cursor: not-allowed;
}

.signin-form_container .btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: calc(13px * var(--scale)) calc(20px * var(--scale));
    background: var(--secondary);
    border-radius: calc(300px * var(--scale));
    width: 100%;
    color: #000;
    cursor: pointer;
    font-weight: 700;
    font-size: calc(20px * var(--scale));
    line-height: calc(24px * var(--scale));
}

.btn-secondary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: calc(13px * var(--scale)) calc(20px * var(--scale));
    background: var(--secondary);
    border-radius: calc(300px * var(--scale));
    width: 100%;
    color: #000;
    cursor: pointer;
    font-weight: 700;
    font-size: calc(20px * var(--scale));
    line-height: calc(24px * var(--scale));
}

.btn-not-filled{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: calc(13px * var(--scale)) calc(20px * var(--scale));
    background: transparent;
    border-radius: calc(300px * var(--scale));
    width: 100%;
    color: #fff;
    cursor: pointer;
    font-weight: 700;
    font-size: calc(20px * var(--scale));
    line-height: calc(24px * var(--scale));
}

/* Customize the dropdown indicator */
.react-select__indicator {
    color: #fff;
}

/* Customize the placeholder */
.react-select__placeholder {
    color: #fff;
}

/* Customize the selected value */
.react-select__single-value {
    color: #fff;
}

/* Customize the menu */
.react-select__menu {
    background-color: #07161c;
    border: none;
}

/* Customize the menu list */
.react-select__menu-list {
    padding: 0;
    background-color: #07161c;
}

/* Customize the menu option */
.react-select__option {
    padding: calc(8px * var(--scale)) calc(16px * var(--scale));
}

/* Customize the menu option when selected */
.react-select__option--is-selected {
    background-color: #07161c;
    color: #fff;
}

.home-header{
    padding: calc(19px * var(--scale)) calc(44px * var(--scale));
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home-container{
    display: flex;
    align-items: center;
    height: calc(100% - (108px * var(--scale)));
}

.home-header .sign-buttons{
    margin-bottom: calc(18px * var(--scale));
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px
}

.home-header .signin-link, .home-header .signup-link{
    font-weight: 700;
    font-size: calc(18px * var(--scale));
    line-height: calc(22px * var(--scale));
}

.home-header .signup-link{
    background: #00E0D1;
    color: #000;
    border-radius: 300px;
    padding: calc(4px * var(--scale)) calc(50px * var(--scale));
}

.home-header .page-menu{
    display: flex;
    gap: 90px;
}

.home-header .page-menu_item{
    font-size: calc(18px * var(--scale));
    line-height: calc(22px * var(--scale));
    color: #556B7F;
}

.home-header .page-menu_item:hover{
    color: #FFFFFF;
}
.home-main_content{
    display: flex;
    flex-direction: column;
    max-width: calc(800px * var(--scale));
    margin-left: calc(130px * var(--scale));
}

.home-main_content .maincontent-title{
    font-weight: 700;
    font-size: calc(60px * var(--scale));
    line-height: calc(60px * var(--scale));
}

.text-highlighted{
    color: var(--secondary);
}

.home-main_content .maincontent-subtitle{
    font-weight: 400;
    font-size: calc(20px * var(--scale));
    line-height: calc(30px * var(--scale));
    color: #BFD0D8;
    max-width: calc(600px * var(--scale));;
}

.home-main_content .maincontent-btn_container{
    max-width: calc(230px * var(--scale));
}

.home-main_content .maicontent-separator{
    width: calc(300px * var(--scale));
    height: 2px;
    background-color: #324457;
    margin: calc(16px * var(--scale)) 0;
}

.homelogin-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: calc(30px * var(--scale));
    width: calc(500px * var(--scale));
    margin-top: calc(200px * var(--scale));
    justify-content: center;
    margin: 30px 0px;
    min-height: 300px;
}
.homelogin-container.inner-container-width {
    width: 100%;
}
.homelogin-container.inner-container-width .inner-container {
    max-width: calc(500px * var(--scale));
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.search-input-dropdwn{
    position: absolute;
    background-color: #07161c;
    width: 100%;
    border-radius: 0 0 20px 20px;
    border: 1px solid var(--secondary);
    border-top: 0;
    top: 17px;
    padding: 0 0 0px 0px;
    z-index: 1;
    /* overflow: hidden; */
    max-height: 397px;
    overflow-y: auto;
    overflow-x: hidden;
}

.home-input_open{
    position: relative;
    z-index: 20;
    border: 1px solid var(--secondary);
    border-bottom: 0; 
    border-radius: 20px 20px 0 0;
    transition: none;
}

.homelogin-container .search-input-wrpr .input {
    font-size: calc(18px * var(--scale));
    line-height: calc(22px * var(--scale));
    padding-left: calc(48px * var(--scale));
    background: url('../images/magnifier-icon.svg') #07161C no-repeat;
    background-size: calc(22px * var(--scale));
    background-position: calc(16px * var(--scale)) calc(12px * var(--scale));
}

.homelogin-container .search-input-wrpr .input.home-input_open{
    background: url('../images/logo-circle-icon.svg') #07161C no-repeat;
    background-position: calc(16px * var(--scale)) calc(13px * var(--scale));
    background-size: calc(22px * var(--scale));
}

.homelogin-container .search-input-wrpr .input::placeholder{
    text-align: center;
}

.search-input-wrpr .input.home-input_open::placeholder{
    text-align: left;
}

.search-item_block{
    background-color: #0B2028;
}

.search-item_title{
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: calc(20px * var(--scale));
    line-height: calc(24px * var(--scale));
}

.search-item_subtext{
    font-size: calc(16px * var(--scale));
    line-height: calc(20px * var(--scale));
    color: #506D8B;
    padding-left: 24px;
}

.search-item_category{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px calc(18px * var(--scale)) 10px calc(18px * var(--scale));
    border-bottom: 1px solid #1d2733;
    background-color: #0B2028;
    /* margin-top: 20px; */
}

.search-item_category_title{
    font-weight: 700;
    font-size: calc(18px * var(--scale));
    line-height: calc(22px * var(--scale));
    color: #00E0D1;
}

.search-item_category_clear{
    font-weight: 400;
    font-size: calc(16px * var(--scale));
    line-height: calc(19px * var(--scale));
    cursor: pointer;
}

.search-btn_drop_container{
    background-color: #0B2028;
    padding: calc(12px * var(--scale)) calc(124px * var(--scale));
}

.full-height-home{
    height: calc(100% - (64px * var(--scale)));
    display: flex;
    justify-content: center;
}

.menu-selectbox{
    top: calc(32px * var(--scale)) !important;
}

.mobile-hide{
    display: block;
}
.mobile-show{
    display: none;
}
.home-loggedin-button{
    width: 250px;
}

.search-item_block a {
    padding: calc(13px * var(--scale)) calc(16px * var(--scale));
    /* padding-left: 0; */
    display: flex;
    position: relative;
}
.search-item_block a:hover {
    background: #0C202B;
}

.search-item_block a .icon-wrpr {
    width: calc(24px * var(--scale));
    margin-right: calc(6px * var(--scale));
    display: flex;
    justify-content: center;
}

.search-item_block a .text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.search-item_block a .text-content h4 {
    font-weight: 500;
    font-size: calc(20px * var(--scale));
    font-family: var(--fotnFamilyInter);
    line-height: calc(24px * var(--scale));
    color: #FFFFFF;
}

.search-item_block a .text-content .location {
    font-weight: 400;
    font-size: calc(16px * var(--scale));
    line-height: calc(20px * var(--scale));
    color: #506D8B;
    margin-top: calc(2px * var(--scale));
}

.search-item_block:not(:last-child) a:after {
    content: "";
    display: block;
    width: calc(100% - calc(16px * var(--scale)));
    position: absolute;
    left: calc(16px * var(--scale));
    bottom: 0;
    height: 1px;
    background: #1D2733;
}

.ico-search-input{
    max-height: calc(24px * var(--scale));
}


/* input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #07161c inset !important;
    -webkit-text-fill-color: var(--white) !important;
    transition: background-color 5000s ease-in-out 0s;
} */

.search-box-disclaimer {
    color: rgb(191, 208, 216);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
    max-width: calc(800px * var(--scale));
    width: 100%;
    margin: 0 auto;
}