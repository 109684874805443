:root {
    --defaultTextColor: #fff;
    --white: #fff;
    --primary: blue;
    --secondary: #00E0D1;
    --warning: yellow;
    --danger: red;
    /* tipografy */
    --fontFamily: 'Proxima Nova';
    --fontFamilyInter: 'Inter', sans-serif;
    --defaultFontSize: 16px;
    --defaultLineHeight: 18px;
    --h1Size: 50px;
    --h2Size: 40px;
    --h3Size: 30px;
    --h4Size: 20px;
    --h5Size: 10px;
    --defaultRaduis: 5px;
    --header-fixed-padding: 70px;
    --accent-color: #00E0D1;
    --backgroundColor: #07161C;
    --scale: 0.8;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

body {
    font-size: 100%;
    font-weight: normal;
    font-family: var(--fontFamily);
    font-weight: 400;
    font-size: var(--defaultFontSize);
    line-height: var(--defaultLineHeight);
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    color: var(--defaultTextColor);
    background-color: var(--backgroundColor);
}

body.lock {
    overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: bold;
    font-family: var(--fontFamily);
    line-height: 120%;
}

h1 {
    font-size: var(--h1Size);
}

h2 {
    font-size: var(--h2Size);
}

h3 {
    font-size: var(--h3Size);
}

h4 {
    font-size: var(--h4Size);
}

h5 {
    font-size: var(--h5Size);
}

.input,
.textarea {
    padding: 10px 0;
    font-size:  var(--defaultFontSize);
    width: 100%;
    background-color: transparent;
    border-bottom: 1px solid #BABDDB;
}
input,
button,
textarea {
    font-family: var(--fontFamily);
}

.radius-5 {
    border-radius: var(--defaultRaduis);
}
.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    overflow: hidden;
}
.header-fixed {
    padding-top: var(--header-fixed-padding);
}
.content {
    flex: auto;
}
.container {
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
    padding: 0 15px;
}

/* colors */

.color-primary {
    color: var(--primary);
}
.color-danger {
    color: var(--danger);
}
.color-warning {
    color: var(--warning);
}
.color-secondary {
    color: var(--secondary);
}

/* bg-colors */

.bg-primary {
    background-color: var(--primary);
}
.bg-danger {
    background-color: var(--danger);
}
.bg-warning {
    background-color: var(--warning);
}
.bg-secondary {
    background-color: var(--secondary);
}

.mb-12{
    margin-bottom: calc(12px * var(--scale));
}
.mb-16{
    margin-bottom: calc(16px * var(--scale));
}
.mb-10{
    margin-bottom: calc(10px * var(--scale));
}
.mb-30{
    margin-bottom: calc(30px * var(--scale));
}
.mb-40{
    margin-bottom: calc(40px * var(--scale));
}

.mt-5{
    margin-top: calc(5px * var(--scale));
}

.mt-12{
    margin-top: calc(12px * var(--scale));
}

.mt-20{
    margin-top: calc(20px * var(--scale));
}

.mt-30{
    margin-top: calc(30px * var(--scale));
}

.max-w-250{
    max-width: calc(250px * var(--scale));
}

.mx-auto{
    margin: 0 auto;
}

.d-flex{
    display: flex;
}
.center-flex{
    display: flex;
    align-items: center;
    justify-content: center;
}

.App, #root{
    height: 100%;
}