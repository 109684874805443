@media (max-width: 1199.98px) {

}

@media (max-width: 991.98px) {
    .left-nav {
        position: fixed;
        left: 0;
        top: calc(60px * var(--scale));
        z-index: 999;
        transform: translateX(-100%);
        transition: 0.15s all ease-in-out;
        height: calc(100vh - calc(60px * var(--scale)));
        overflow-y: auto;
    }
    .left-nav.open {
        transform: translateX(0);
    }
    .dashboard-sidebar {
        width: 100%;
        min-width: initial;
        padding-bottom: 16px;
    }
    .header {
        padding: 5px 10px;
    }
    .header-settings-link {
        min-width: 50px;
        min-height: 50px;
        justify-content: center;
    }
    .header-logo-icon {
        min-width: 50px;
        min-height: 50px;
        cursor: pointer;
        margin-right: initial;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .header-logo-icon svg {
        width: 30px;
    }
    .header-logo-img {
        margin: 0 auto;
    }
    .header-logo-img+.header-settings-link {
        margin-left: 0;
    }
    .header-settings-link .link-text {
        display: none;
    }
    :root {
        --scale: 1;
    }
    .dashboard-header {
        display: none;
    }
    .table-page-header-box {
        padding: 20px 16px 11px;
    }
    .table-page-header-box h1 {
        font-size: calc(32px * var(--scale));
        line-height: calc(40px * var(--scale));
    }
    .table-page-header-box h1 span {
        font-size: calc(22px * var(--scale));
        line-height: calc(40px * var(--scale));
    }
    .hide_on_mob,
    .table th.hide_on_mob,
    .table td.hide_on_mob {
        display: none;
    }
    .table-page-tablebox .filter-btns-group {
        overflow-x: auto;
        flex-wrap: initial;
    }
    .table-page-tablebox .filter-btns-group::-webkit-scrollbar {
        height: 2px;
    }
    .table-page-header-box .filter-btns-group.items-min-w-140 li {
        min-width: calc(33.33% - 10px * var(--scale));
    }
    .table-page-tablebox-header .search-input-wrpr {
        display: none;
    }
    .table-page-tablebox-header h3 {
        font-size: calc(24px * var(--scale));
        line-height: calc(30px * var(--scale));
    }
    .table-page-tablebox {
        display: flex;
        flex-direction: column;
        padding: 5px 16px 10px;
    }
    .table-page-tablebox .table-page-tablebox-header {
        order: 1;
        background: #07161C;
        margin: 0 -16px;
        padding: 26px 16px 16px;
        width: calc(100% + 32px);
    }
    .table-page-tablebox .filter-btns-group {
        order: 0;
    }
    .table-page-tablebox .table-wrpr {
        order: 2;
    }
    .table-page-tablebox .table-wrpr+.sidebar-pagination {
        order: 3;
    }
    .show-in-mob {
        display: block;
    }
    .table-page-header-box-wrpr+.show-in-mob .search-input-wrpr {
        padding: 10px 16px;
        margin-bottom: 5px;
        width: 100%;
        background: #081921;
    }
    .table-page-header-box-wrpr+.show-in-mob {
        width: 100%;
    }
    .table-page-header-box-toggler {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        top: 2px;
    }
    .table-page-header-box-toggler:hover {
        filter: brightness(1.3);
    }
    .table-page-header-box h1 {
        padding-right: 35px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .table-page-header-box.header-box-close .info-list,
    .table-page-header-box.header-box-close .filter-btns-group {
        display: none;
    }
    .table-page-header-box.header-box-close .table-page-header-box-toggler {
        transform: rotate(180deg);
    }
    .table-page-header-box.header-box-close h1 {
        margin-bottom: 0;
        border: 0;
    }
    .table-page-header-box.header-box-close {
        padding-bottom: 0;
        border-bottom: 1px solid #1D2733;
    }
    .product-page-with-map {
        flex-direction: column;
    }
    .product-page-with-map .google-map {
        height: 250px;
        width: 100%;
        margin: 5px 0;
    }
    .header-box-close+.google-map {
        display: none;
    }
    .update-date span {
        color: #fff;
    }
    .modal-unlock-premium-body h2 {
        font-size: 40px;
        line-height: 44px;
    }
    .modal-unlock-premium-body p {
        font-size: 14px;
        line-height: 26px;
    }
    .modal-unlock-premium-body {
        padding: 60px 20px 30px;
    }
    .dashboard-content {
        height: calc(100vh - 70px);
    }
    .settings-sidebar-header .close-btn {
        display: none;
    }
    .hide-on-mob {
        display: none;
    }
    .signin-form_container .heading {
        text-align: center;
    }
    .table-page-header-box-wrpr+.show-in-mob .search-input-wrpr .clear-input {
        right: 32px;
    }
    .tph-cards-wrpr .table-page-header-box.w-25,
    .tph-cards-wrpr .col-50 .table-page-header-box.w-50 {
        width: calc(50% - calc(5px * var(--scale)));
    }
    .tph-cards-wrpr .table-page-header-box.w-50 {
        width: calc(100% - calc(5px * var(--scale)));
    }
    .table-page-header-box.header-box-close~.table-page-header-box,
    .table-page-header-box.header-box-close .table-page-header-box {
        display: none;
    }
    .table-page-header-box.show-in-mob .map-box {
        min-height: 500px;
        border-bottom: 1px solid #1B485B;
        margin-bottom: 24px;
        padding-bottom: 24px;
    }
    .table-page-header-box.show-in-mob .map-box .map-Div {
        min-height: 500px;
    }
    .map-box img {
        height: calc(100% - 24px);
        top: 0;
        transform: translateX(-50%);
    }
    .card-product-info-footer .lock-features-icon {
        display: none;
    }
    .table thead th {
        padding-right: 10px;
    }
    .tph-cards-wrpr .col-50 {
        width: 100%;
    }
    .table-page-header-box h1.b-0 {
        padding-bottom: calc(10px * var(--scale));
        margin-bottom: calc(16px * var(--scale));
        border-bottom: 1px solid #1B485B;
    }
    .table-page-header-box.header-box-close h1.b-0 {
        margin-bottom: 0;
        border: 0;
    }
    .table-page-header-box-wrpr+.show-in-mob.w-100 .search-input-wrpr {
        padding: 0;
    }
    .tph-cards-wrpr .table-page-header-box.table-page-header-box-wrpr {
        padding-top: 10px;
    }
    .stores-page-dateinstock-wrpr {
        flex-direction: column;
        align-items: flex-end;
    }
    .stores-page-dateinstock-wrpr .vertical-margin-line {
        display: none;
    }
    .stores-page-dateinstock-wrpr .toggle-input {
        margin-top: 3px;
    }
}

@media (max-width: 767.98px) {

}

@media (max-width: 575.98px) {

}

@media (max-width: 390px) {
    .table-page-tablebox .table-page-tablebox-header {
        flex-direction: column;
        align-items: flex-start;
        padding-top: 16px;
    }
    .table th {
        font-size: 18px;
    }
    .table td {
        padding: 16px;
    }
    .table-page-header-box h1 {
        font-size: 24px;
        /* line-height: 28px; */
    }
    .table-page-header-box h1 span {
        font-size: 18px;
        /* line-height: 28px; */
    }
    .card-product-info-header h3 {
        font-size: 80px;
        line-height: 60px;
    }
    .card-product-info-header {
        min-height: 70px;
    }
    .table-page-header-box.table-page-header-box-wrpr {
        padding-top: 10px;
    }
}
@media (max-width: 375px) {
    .tph-cards-wrpr .table-page-header-box.w-25,
    .tph-cards-wrpr .col-50 .table-page-header-box.w-50 {
        width: calc(100% - calc(5px * var(--scale)));
    }
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}
