.header {
    display: flex;
    align-items: center;
    padding-top: calc(14px * var(--scale));
    padding-bottom: calc(14px * var(--scale));
    padding-left: calc(15px * var(--scale));
    padding-right: calc(20px * var(--scale));
    background: #081921;
    margin-bottom: calc(5px * var(--scale));
    max-height: calc(60px * var(--scale));
}
.header-logo-icon {
    display: flex;
    align-items: center;
    margin-right: calc(36px * var(--scale));
    width: calc(30px * var(--scale));
}
.header-logo-icon svg {
    width: 100%;
}
.header-logo-img {
    display: flex;
    align-items: center;
    width: calc(100px * var(--scale));
    position: relative;
}
/* .header-logo-img:after {
    content: "BETA";
    position: absolute;
    left: calc(100% + 5px);
    top: 0;
    font-size: 12px;
    line-height: 1;
    color: #00E0D1;
} */
.header-logo-img svg {
    width: 100%;
}
.header-settings-link { 
    font-style: normal;
    font-weight: 600;
    font-size: calc(16px * var(--scale));
    line-height: calc(18px * var(--scale));
    color: #BFD0D8;
    display: flex;
    align-items: center;
    margin-left: auto;
}
.header-settings-link .link-text {
    margin-right: calc(12px * var(--scale));
}
.header-settings-link:hover {
    color: #fff;
}
.header-settings-link .link-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}
.header-settings-link .link-icon svg {
    width: calc(24px * var(--scale));
    height: calc(24px * var(--scale));
}