@media (max-width: 1199.98px) {

}

@media (max-width: 991.98px) {
    .mobile-hide{
        display: none;
    }
    .mobile-show{
        display: block;
    }
    .home-header .page-menu{
        display: none;
    }
    .home-header .sign-buttons{
        display: none;
    }
    .home-main_content{
        margin-left: 0;
        min-height: calc(100vh - 75px);
    }
    .home-container{
        padding: 0  calc(34px * var(--scale));
    }
    .home-main_content .maincontent-title{
        font-weight: 700;
        font-size: calc(50px * var(--scale));
        line-height: calc(48px * var(--scale));
        margin-top: auto;
    }
    .home-main_content .maincontent-btn_container{
        max-width: 400px;
        width: 100%;
        margin: auto auto 0;
        display: flex;
        flex-direction: column;
        gap: calc(12px * var(--scale));
    }
    .auth-top_link{
        padding: calc(20px * var(--scale)) calc(30px * var(--scale));
    }
    .signIn-left_container{
        display: none;
    }

    .sign-footer_mobile{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .menu-selectbox{
        top: calc(22px * var(--scale)) !important;
    }

    .home-loggedin-button{
        margin-top: auto;
        margin-bottom: calc(30px * var(--scale));
        width: 400px;
    }

    .homelogin-container{
        padding: 0 calc(50px * var(--scale));
        margin-top: calc(200px * var(--scale));
    }

    .mobile-input-wrpr-open{
        width: 100%;
        position: fixed;
        top: 65px;
        left: 0;
        height: calc(100vh - 65px);
        display: flex;
        flex-direction: column;
    }

    .mobile-input-wrpr-open .home-input_open{
        border-radius: 0;
    }

    .mobile-input-wrpr-open .search-input-dropdwn{
        border: none;
        border-radius: 0;
        top: 30px;
        position: initial;
        max-height: initial;
        overflow-y: initial;
    }
    
    .mobile-input-wrpr-open .home-input_open.mobile-home-input_open{
        border-radius: 300px;
        border: 1px solid var(--secondary);
    }

    .input-additional-conatiner_mobile{
        display: flex;
        padding: 10px;
        align-items: center;
        gap: 10px;
        background-color: #081921;
        position: relative;
    }

    /* .clear-input{
        display: none;
    } */

    .clear-input-mobile {
        width: calc(20px * var(--scale));
        height: calc(20px * var(--scale));
        min-width: calc(20px * var(--scale));
        min-height: calc(20px * var(--scale));
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #1D2733;
        position: absolute;
        right: calc(82px * var(--scale));
        top: 50%;
        transform: translateY(-50%);
    }


}

@media (max-width: 767.98px) {

}

@media (max-width: 575.98px) {
    .signin-form_container{
        padding: 0 15px;
    }
    .home-container{
        padding: 0  calc(24px * var(--scale));
        height: auto;
    }
    .home-main_content .maincontent-btn_container{
        max-width: 100%;
        padding: 50px 50px 34px;
    }
    .home-loggedin-button{
        width: 100%;
    }
}

@media (max-width: 390px) {
    .home-main_content .maincontent-title{
        font-weight: 700;
        font-size: calc(40px * var(--scale));
        line-height: calc(38px * var(--scale));
    }
    .home-main_content .maicontent-separator{
        width: calc(215.75px * var(--scale));
    }
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}
