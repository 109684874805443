.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 9999;
	background: rgba(0, 0, 0, 0.4);
	overflow-y: auto;
	opacity: 0;
	visibility: hidden;
	transition: all 0.15s linear;
    overflow-x: hidden;
}
.modal--show { 
	opacity: 1;
	visibility: visible;
}
.modal--show .modal__dialog {
	transform: none;
}
.modal__dialog {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100%;
	margin: auto;
	padding: 16px;
	transform: translate(0, -20px);
	transition: transform .3s ease-out;
}
.modal__dialog--500 {
	max-width: 540px;
}
.modal__dialog--960 {
	max-width: calc(960px * var(--scale));
}
.modal__content {
    display: flex;
	flex-direction: column;
	width: 100%;
	background: #07161C;
	border-radius: calc(30px * var(--scale));
	transition: all 0.3s ease-out;
	position: relative;
	
}
.modal__body {
	padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.modal__header {
    padding: 22px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}
.modal__footer {
	padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal__title{
    font-weight: 500;
    font-size: 22px;
    line-height: 21px;
}
.modal-close-btn {
    position: absolute;
    top: calc(20px * var(--scale));
    right: calc(20px * var(--scale));
    cursor: pointer;
	width: calc(34px * var(--scale));
	height: calc(34px * var(--scale));
	display: flex;
	align-items: center;
}
.modal-close-btn svg {
	width: 100%;
}
.modal-close-btn svg path:first-child {
	fill: transparent;
}
.modal-unlock-premium-body {
	padding: calc(90px * var(--scale)) calc(30px * var(--scale)) calc(60px * var(--scale));
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}
.modal-unlock-premium-body h2 {
	font-weight: 700;
	font-size: calc(50px * var(--scale));
	line-height: calc(60px * var(--scale));
	color: #FFFFFF;
}
.modal-unlock-premium-body p {
	font-weight: 400;
	font-size: calc(18px * var(--scale));
	line-height: calc(26px * var(--scale));
	color: #BFD0D8;
	margin-bottom: calc(50px * var(--scale));
}
.modal-unlock-premium-body .btn,
.modal-unlock-premium-body .btn.green-btn {
	max-width: calc(400px * var(--scale));
	font-size: calc(20px * var(--scale));
	line-height: calc(24px * var(--scale));
	padding: calc(13px * var(--scale));
}
.modal-unlock-premium-body .btn+.btn {
	margin-top: calc(12px * var(--scale));
}