.d-flex {
    display: flex;
}
.align-items-center {
    align-items: center;
}
.align-items-start {
    align-items: flex-start;
}
.flex-col {
    flex-direction: column;
}
.main-container {
    display: flex;
    width: 100%;
}
.justify-content-sb {
    justify-content: space-between;
}
.justify-content-c {
    justify-content: center;
}
.justify-content-end {
    justify-content: flex-end;
}
.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.px-10 {
    padding-left: calc(10px * var(--scale));
    padding-right: calc(10px * var(--scale));
}
.pb-10 {
    padding-bottom: 10px;
}
.mt-10 {
    margin-top: calc(10px * var(--scale));
}
.mt-16 {
    margin-top: calc(16px * var(--scale));
}
.mt-20 {
    margin-top: calc(20px * var(--scale));
}
.mb-20 {
    margin-bottom: calc(20px * var(--scale));
}
.ml-10 {
    margin-left: 10px;
}
.mr-4 {
    margin-right: calc(4px * var(--scale));
}
.mr-10 {
    margin-right: calc(10px * var(--scale));
}
.mr-15 {
    margin-right: calc(15px * var(--scale));
}
.mr-20 {
    margin-right: calc(20px * var(--scale));
}
.min-h-50 {
    min-height: calc(50px * var(--scale));
}
.mx-auto {
    margin: 0 auto;
}
.max-w-260 {
    max-width: calc(280px * var(--scale));
}
.w-100 {
    width: 100%;
}
.flex-1 {
    flex: 1;
}
.relative {
    position: relative;
}
*::-webkit-scrollbar {
    width: calc(6px * var(--scale));
}
*::-webkit-scrollbar-track {
    background-color: #081921;
}
*::-webkit-scrollbar-thumb {
    background-color: #506D8B;
}
.text-underline {
    text-decoration: underline;
}
.left-nav {
    padding: calc(5px * var(--scale));
    background: #081921;
    height: calc(100vh - calc(65px * var(--scale)));
    margin-right: calc(5px * var(--scale));
}
.left-nav li a {
    color: #324457;
    width: calc(50px * var(--scale));
    height: calc(50px * var(--scale));
    display: flex;
    align-items: center;
    justify-content: center;
}
.left-nav li a:hover,
.left-nav li a.active {
    color: #00E0D1;
}
.left-nav li+li {
    margin-top: calc(5px * var(--scale));
}
.dashboard-container,
.dashboard-main,
.dashboard-content {
    display: flex;
    width: 100%;
}
.dashboard-content {
    height: calc(100vh - calc(130px * var(--scale)));
    overflow-y: auto;
}
.main-neuro-world-image {
    max-width: calc(716px * var(--scale));
    margin: 0 auto;
    display: block;
}
.dashboard-header {
    width: 100%;
    min-height: calc(60px * var(--scale));
    background: #081921;
    padding: calc(10px * var(--scale)) calc(15px * var(--scale));
    overflow-x: auto;
    max-width: calc(100vw - calc(536px * var(--scale)));
    margin-bottom: calc(5px * var(--scale));
}
.dashboard-sidebar {
    min-width: calc(460px * var(--scale));
    width: calc(460px * var(--scale));
    margin-right: calc(5px * var(--scale));
    /* overflow-y: auto; */
    /* max-height: calc(100vh - calc(65px * var(--scale))); */
    transition: 0.15s all ease-in-out;
}
.sm-wrpr-box {
    background: #081921;
    padding: calc(5px * var(--scale)) calc(10px * var(--scale));
    margin-bottom: calc(5px * var(--scale));
}
.filter-btns-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 calc(-5px * var(--scale));
}
.filter-btns-group.mb-20 {
    margin-bottom: calc(20px * var(--scale));
}
.filter-btns-group li {
    /* margin-left: calc(5px * var(--scale));
    margin-right: calc(5px * var(--scale)); */
    margin: calc(5px * var(--scale));
}
.dashboard-header .filter-btns-group li {
    margin: 0 calc(5px * var(--scale));
}
.filter-btns-group.w-33 li {
    width: calc(33.33% - calc(10px * var(--scale)));
}
.filter-btns-group.items-min-w-140 li {
    min-width: calc(140px * var(--scale));
}
.filter-btns-group li>* {
    font-weight: 400;
    font-size: calc(15px * var(--scale));
    line-height: calc(18px * var(--scale));
    color: #BFD0D8;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: calc(11px * var(--scale));
    text-align: center;
    background: #0C202B;
    border-radius: calc(300px * var(--scale));
    width: 100%;
}
.filter-btns-group li>*.active {
    background: #506D8B;
    font-weight: 700;
    color: #fff;
}
.filter-btns-group li>*:hover {
    background: #506D8B;
}
.filters-result-text {
    font-weight: 700;
    font-size: calc(16px * var(--scale));
    line-height: calc(20px * var(--scale));
    color: #506D8B;
}
.filters-result-text span {
    color: #00E0D1;
}
.filters-result-text + a {
    max-width: calc(50px * var(--scale));
    max-height: calc(50px * var(--scale));
}
/* .filters-result-text + a svg {
    transform: scale(var(--scale));
} */
.search-sidebar-result-box {
    padding-top: calc(10px * var(--scale));
    background: #081921;
    margin-bottom: calc(5px * var(--scale));
}
.search-sidebar-result-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: calc(13px * var(--scale)) 0;
    border-bottom: 1px solid #1B485B;
    margin-left: calc(16px * var(--scale));
    width: calc(100% - calc(32px * var(--scale)));
}
.search-sidebar-result-header h4 {
    font-weight: 700;
    font-size: calc(24px * var(--scale));
    line-height: calc(29px * var(--scale));
    color: #FFFFFF;
}
.search-sidebar-result-header a {
    font-weight: 400;
    font-size: calc(16px * var(--scale));
    line-height: calc(19px * var(--scale));
    text-decoration: underline;
    color: #00E0D1;
}
.search-sidebar-result-header a:hover {
    color: #506D8B;
}
.search-sidebar-result-list {
    /* padding-left: 16px; */
}
.search-sidebar-result-list li a {
    padding: calc(13px * var(--scale)) calc(16px * var(--scale));
    /* padding-left: 0; */
    display: flex;
    position: relative;
    cursor: pointer;
}
.search-sidebar-result-list li a:hover,
.search-sidebar-result-list li a.active {
    background: #0C202B;
}
.search-sidebar-result-list li:not(:last-child) a:after {
    content: "";
    display: block;
    width: calc(100% - calc(16px * var(--scale)));
    position: absolute;
    left: calc(16px * var(--scale));
    bottom: 0;
    height: 1px;
    background: #1D2733;
}
.search-sidebar-result-list li a .icon-wrpr {
    width: calc(24px * var(--scale));
    margin-right: calc(6px * var(--scale));
    display: flex;
    justify-content: center;
}
.search-sidebar-result-list li a .icon-wrpr svg {
    max-width: calc(24px * var(--scale));
    max-height: calc(24px * var(--scale));
}
.search-sidebar-result-list li a .text-content h4 {
    font-weight: 500;
    font-size: calc(20px * var(--scale));
    font-family: var(--fotnFamilyInter);
    line-height: calc(24px * var(--scale));
    color: #FFFFFF;
    margin-bottom: calc(2px * var(--scale));
}
.search-sidebar-result-list li a .text-content .location {
    font-weight: 400;
    font-size: calc(16px * var(--scale));
    line-height: calc(20px * var(--scale));
    color: #506D8B;
}
.search-input-wrpr {
    position: relative;
}
.search-input-wrpr .input {
    font-size: calc(18px * var(--scale));
    line-height: calc(22px * var(--scale));
    padding-left: calc(48px * var(--scale));
    background: url('../images/logo-circle-icon.svg') #07161C no-repeat;
    background-size: calc(20px * var(--scale));
    background-position: calc(16px * var(--scale)) calc(15px * var(--scale));
}
.search-input-wrpr .input::placeholder {
    color: #506D8B;
}
.clear-input {
    width: calc(20px * var(--scale));
    height: calc(20px * var(--scale));
    min-width: calc(20px * var(--scale));
    min-height: calc(20px * var(--scale));
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1D2733;
    position: absolute;
    right: calc(16px * var(--scale));
    top: 50%;
    transform: translateY(-50%);
}
.clear-input:hover {
    background: #303e50;
}
.clear-input svg {
    width: calc(10px * var(--scale));
    height: calc(10px * var(--scale));
}
.amount_circle_result {
    min-width: calc(30px * var(--scale));
    min-height: calc(30px * var(--scale));
    background: #00E0D1;
    border-radius: calc(300px * var(--scale));
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: calc(18px * var(--scale));
    line-height: calc(20px * var(--scale));
    color: #FFFFFF;
}
.sidebar-back-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: calc(50px * var(--scale));
    max-height: calc(50px * var(--scale));
}
.green-rounded-btn,
.btn.green-rounded-btn.grey-btn {
    padding: calc(19px * var(--scale));
    background: #00E0D1;
    border-radius: calc(300px * var(--scale));
    font-weight: 700;
    font-size: calc(24px * var(--scale));
    line-height: calc(24px * var(--scale));
    color: #000000;
    width: 100%;
}
.btn.green-rounded-btn.grey-btn {
    background: #324457;
    color: #000000;
}
.sidebar-accordion {
    background: #081921;
    margin-bottom: calc(5px * var(--scale));
}
.sidebar-accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: calc(23px * var(--scale)) calc(16px * var(--scale));
    cursor: pointer;
}
.sidebar-accordion-header h4 {
    font-weight: 700;
    font-size: calc(24px * var(--scale));
    line-height: calc(29px * var(--scale));
    color: #FFFFFF;
    transition: 0.15s all ease-in-out;
}
.sidebar-accordion-header:hover h4 {
    color: #00E0D1;
}
.sidebar-accordion-body {
    display: none;
    padding: 0 calc(16px * var(--scale)) calc(10px * var(--scale));
}
.sidebar-accordion.active .sidebar-accordion-body {
    display: block;
}
.sidebar-accordion .arrow-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}
.sidebar-accordion.active .arrow-icon {
    transform: rotate(180deg);
}
.sidebar-accordion .arrow-icon svg {
    max-width: calc(24px * var(--scale));
    max-height: calc(24px * var(--scale));
}
.filter_checkboxes_list li label {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: calc(20px * var(--scale));
    font-family: var(--fotnFamilyInter);
    line-height: calc(24px * var(--scale));
    color: #FFFFFF;
    padding: calc(19px * var(--scale)) calc(20px * var(--scale));
    border-radius: calc(300px * var(--scale));
    cursor: pointer;
    transition: 0.15s all ease-in-out;
}
.filter_checkboxes_list li label:hover {
    opacity: 0.7;
}
.filter_checkboxes_list li:nth-child(odd) label {
    background: #07161C;
}
.filter_checkboxes_list li label input {
    display: none;
}
.filter_checkboxes_list li label .checkbox_circle,
.checkbox_circle {
    width: calc(30px * var(--scale));
    height: calc(30px * var(--scale));
    min-width: calc(30px * var(--scale));
    min-height: calc(30px * var(--scale));
    border-radius: 50%;
    background: #081921;
    border: 1px solid #506D8B;
    position: relative;
    margin-right: calc(15px * var(--scale));
}
.checkbox_circle.small {
    width: calc(20px * var(--scale));
    height: calc(20px * var(--scale));
    min-width: calc(20px * var(--scale));
    min-height: calc(20px * var(--scale));
}
.filter_checkboxes_list li label input:checked+.checkbox_circle:after,
.checkbox_circle.active:after,
.search-sidebar-result-list li a.active .checkbox_circle:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #00E0D1;
    width: calc(20px * var(--scale));
    height: calc(20px * var(--scale));
    border-radius: 50%;
}
.filter_checkboxes_list li label input:checked+.checkbox_circle.small:after,
.checkbox_circle.small.active:after,
.search-sidebar-result-list li a.active .checkbox_circle.small:after {
    width: calc(14px * var(--scale));
    height: calc(14px * var(--scale));
}
.toggle-input {
    cursor: pointer;
    display: flex;
    align-items: center;
}
.toggle-input .toggle-input-box {
    background: #0C202B;
    border-radius: calc(300px * var(--scale));
    width: calc(54px * var(--scale));
    height: calc(30px * var(--scale));
    min-width: calc(54px * var(--scale));
    min-height: calc(30px * var(--scale));
    position: relative;
    transition: 0.15s all ease-in-out;
}
.toggle-input input {
    display: none;
}
.toggle-input input:checked ~ .toggle-input-box {
    background: #00E0D1;
}
.toggle-input .toggle-input-circle {
    width: calc(24px * var(--scale));
    height: calc(24px * var(--scale));
    min-width: calc(24px * var(--scale));
    min-height: calc(24px * var(--scale));
    border-radius: 50%;
    background: #506D8B;
    display: block;
    position: absolute;
    top: 50%;
    left: calc(3px * var(--scale));
    transform: translateY(-50%);
    z-index: 2;
    transition: 0.15s all ease-in-out;
}
.toggle-input input:checked ~ .toggle-input-box .toggle-input-circle {
    background: #07161C;
    left: calc(27px * var(--scale));
}
.toggle-input .toggle-input-label {
    font-weight: 400;
    font-size: calc(16px * var(--scale));
    line-height: calc(20px * var(--scale));
    color: #00E0D1;
    margin-right: calc(12px * var(--scale));
}
.remove-circle-btn {
    background: #081921;
    width: calc(16px * var(--scale));
    height: calc(16px * var(--scale));
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: calc(10px * var(--scale));
}
.remove-circle-btn:hover {
    background: #506D8B;
}
.table-page-header-box {
    padding: calc(30px * var(--scale)) calc(20px * var(--scale)) calc(20px * var(--scale));
    background: #081921;
    width: 100%;
    margin-bottom: calc(5px * var(--scale));
}

.table-page-header-box h1 {
    font-weight: 700;
    font-size: calc(40px * var(--scale));
    line-height: calc(49px * var(--scale));
    color: #FFFFFF;
    padding-bottom: calc(10px * var(--scale));
    margin-bottom: calc(16px * var(--scale));
    border-bottom: 1px solid #1B485B;
    max-width: calc(810px * var(--scale));
}
.table-page-header-box h1 span {
    color: #BFD0D8;
    font-size: calc(25px * var(--scale));
    line-height: calc(30px * var(--scale));
}
.info-list li {
    display: flex;
    align-items: flex-start;
    font-weight: 400;
    font-size: calc(22px * var(--scale));
    font-family: var(--fotnFamilyInter);
    line-height: calc(24px * var(--scale));
    color: #FFFFFF;
}
.info-list li+li {
    margin-top: 18px;
}
.info-list li .icon-wrpr {
    width: calc(24px * var(--scale));
    height: calc(24px * var(--scale));
    margin-right: calc(12px * var(--scale));
    display: flex;
    align-items: center;
    justify-content: center;
}
.green-link {
    color: #00E0D1;
    text-decoration: underline;
}
.color-grey {
    color: #BFD0D8;
}
.color-green {
    color: #00E0D1;
}
.table-page-tablebox {
    background: #081921;
    padding: calc(20px * var(--scale)) calc(15px * var(--scale)) calc(20px * var(--scale)) calc(20px * var(--scale));
    width: 100%;
}
.table-page-tablebox-header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: calc(20px * var(--scale));
}
.table-page-tablebox-header h3 {
    /* width: 100%;
    flex: 1; */
    font-weight: 700;
    font-size: calc(30px * var(--scale));
    line-height: calc(38px * var(--scale));
    color: #FFFFFF;
}
.table-page-tablebox-header .search-input-wrpr {
    max-width: calc(430px * var(--scale));
    width: 100%;
}
.table-page-tablebox-header .update-date+.search-input-wrpr {
    padding-left: calc(20px * var(--scale));
    margin-left: calc(20px * var(--scale));
    border-left: 1px solid #1B485B;
}
.update-date {
    font-weight: 400;
    font-size: calc(15px * var(--scale));
    line-height: calc(18px * var(--scale));
    color: #506D8B;
}
.update-date span {
    color: #00E0D1;
}
.table-sort-btn {
    width: calc(26px * var(--scale));
    height: calc(26px * var(--scale));
    min-width: calc(26px * var(--scale));
    min-height: calc(26px * var(--scale));
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #1D2733;
    margin-right: calc(10px * var(--scale));
    color: #506D8B;
}
.table-sort-btn svg {
    transform: scale(var(--scale));
}
.table-sort-btn.asc,
.table-sort-btn.desc {
    color: #00E0D1;
}
.table-sort-btn.desc svg {
    transform: scale(var(--scale)) rotate(180deg);
}
.table-wrpr {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
}
.table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}
.table th {
    padding: calc(16px * var(--scale)) 0;
    font-weight: 700;
    font-size: calc(18px * var(--scale));
    line-height: calc(20px * var(--scale));
    color: #FFFFFF;
    border-bottom: 1px solid #1B485B;
}
.th-inner-wrpr {
    display: flex;
    align-items: center;
}
.table td {
    font-weight: 400;
    font-size: calc(14px * var(--scale));
    line-height: calc(17px * var(--scale));
    color: #BFD0D8;
    padding: calc(9px * var(--scale)) 0;
    padding-right: calc(24px * var(--scale));
    padding-left: calc(36px * var(--scale));
}
.table td.pl-0 {
    padding-left: 0;
}
.table tbody tr:nth-child(even) td {
    background: #07161C;
}
.table tbody tr:nth-child(even) td:first-child {
    border-radius: calc(300px * var(--scale)) 0 0 calc(300px * var(--scale));
}
.table tbody tr:nth-child(even) td:last-child {
    border-radius: 0 calc(300px * var(--scale)) calc(300px * var(--scale)) 0;
}
.dashboard-sidebar-header {
    display: flex;
    align-items: center;
}
.dashboard-sidebar-header .search-input-wrpr {
    width: 100%;
    flex: 1;
}
.dashboard-sidebar-header .search-input-wrpr + a {
    width: calc(50px * var(--scale));
    height: calc(50px * var(--scale));
    min-width: calc(50px * var(--scale));
    min-height: calc(50px * var(--scale));
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: calc(10px * var(--scale));
    color: #506D8B;
}
.dashboard-sidebar-header .search-input-wrpr + a:hover {
    color: #fff;
}
.dashboard-sidebar-header .search-input-wrpr + a svg {
    transform: scale(var(--scale));
}
.dashboard-sidebar-header h4 {
    font-weight: 700;
    font-size: calc(24px * var(--scale));
    line-height: calc(30px * var(--scale));
    color: #FFFFFF;
}
.dashboard-sidebar.hidden {
    width: 0;
    min-width: 0;
    /* overflow: hidden; */
    /* transform: translateX(-100%); */
}
.open-sidebar-btn {
    background: #081921;
    width: calc(60px * var(--scale));
    height: calc(60px * var(--scale));
    min-width: calc(60px * var(--scale));
    min-height: calc(60px * var(--scale));
    display: flex;
    align-items: center;
    justify-content: center;
}
.dashboard-sidebar-close .dashboard-header {
    max-width: calc(100vw - calc(136px * var(--scale)));
}
.google-map {
    height: calc(100% - 3px);
    width: 33.33%;
    /* max-width: 530px; */
    margin-left: 5px;
    margin-bottom: 5px;
}
.settings-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
}
.settings-sidebar {
    background: #081921;
    max-width: calc(400px * var(--scale));
    width: 100%;
    margin-right: 5px;
    height: calc(100vh - calc(70px * var(--scale)));
    overflow-y: auto;
}
.settings-sidebar-header {
    font-weight: 700;
    font-size: calc(20px * var(--scale));
    line-height: calc(24px * var(--scale));
    color: #FFFFFF;
    padding: calc(18px * var(--scale)) calc(20px * var(--scale));
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #07161C;
}
.settings-sidebar-header .close-btn {
    width: calc(24px * var(--scale));
    height: calc(24px * var(--scale));
    display: flex;
    align-items: center;
    justify-content: center;
}
.settings-sidebar-header .close-btn svg {
    width: 100%;
}
.settings-sidebar ul.settings-sidebar-nav li {
    width: 100%;
}
.settings-sidebar ul.settings-sidebar-nav li a {
    padding: calc(15px * var(--scale)) calc(20px * var(--scale));
    font-weight: 400;
    font-size: calc(16px * var(--scale));
    line-height: calc(20px * var(--scale));
    color: #7C8DA0;
    display: flex;
    align-items: center;
}
.settings-sidebar ul.settings-sidebar-nav li a .icon-wrpr {
    width: calc(24px * var(--scale));
    height: calc(24px * var(--scale));
    margin-right: calc(10px * var(--scale));
    display: flex;
    align-items: center;
    justify-content: center;
}
.settings-sidebar ul.settings-sidebar-nav li a.active {
    background: #07161C;
    color: #00E0D1;
    font-weight: 600;
}
.settings-sidebar ul.settings-sidebar-nav li a:hover {
    background: #07161C;
}
.settings-sidebar ul.settings-sidebar-nav li a.disabled {
    color: #324457;
    /* pointer-events: none; */
}
.settings-sidebar+.settings-sidebar .settings-sidebar-header {
    color: #00E0D1;
}
.settings-form-group {
    padding-top: calc(10px * var(--scale));
    /* padding-bottom: calc(30px * var(--scale)); */
}
.settings-form-group-header {
    padding: calc(15px * var(--scale)) calc(20px * var(--scale));
    font-weight: 600;
    font-size: calc(16px * var(--scale));
    line-height: calc(20px * var(--scale));
    color: #FFFFFF;
    border-top: 1px solid #07161C;
}
.settings-form-group-header.bt-0 {
    border-top: 0;
}
.settings-form-input-wrpr {
    padding: 0 calc(20px * var(--scale));
}
.settings-form-input-wrpr+.settings-form-input-wrpr {
    margin-top: calc(14px * var(--scale));
}
.settings-form-group .input-label {
    font-size: calc(12px * var(--scale));
    line-height: calc(14px * var(--scale));
}
.settings-form-group .input-label::before {
    display: none;
}
.settings-form-group .input {
    font-size: calc(14px * var(--scale));
    padding: calc(9px * var(--scale)) calc(16px * var(--scale));
}
.input-success-check {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: calc(20px * var(--scale));
    height: calc(20px * var(--scale));
    right: calc(16px * var(--scale));
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}
.input-success-check svg {
    width: 100%;
}
.settings-form-group .password-wrapper .toggle-password {
    width: calc(20px * var(--scale));
    height: calc(20px * var(--scale));
    display: flex;
    align-items: center;
    justify-content: center;
}
.settings-sidebar-btns-group {
    display: flex;
    align-items: center;
    padding: calc(30px * var(--scale)) calc(20px * var(--scale)) calc(10px * var(--scale));
    margin-top: calc(30px * var(--scale));
    border-top: 1px solid #07161C;
}
.settings-sidebar-btns-group .btn:first-child {
    margin-right: calc(8px * var(--scale));
}
.settings-sidebar-btns-group .btn:last-child {
    margin-left: calc(8px * var(--scale));
}
.btn.green-btn {
    text-align: center;
    font-weight: 700;
    font-size: calc(14px * var(--scale));
    line-height: calc(24px * var(--scale));
    border-radius: calc(300px * var(--scale));
    background: #00E0D1;
    color: #000000;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    padding: calc(6px * var(--scale));
}
.btn.grey-btn {
    text-align: center;
    font-weight: 700;
    font-size: calc(14px * var(--scale));
    line-height: calc(24px * var(--scale));
    border-radius: calc(300px * var(--scale));
    background: #324457;
    color: #000000;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    padding: calc(6px * var(--scale));
}
.checkbox_circle.middle {
    width: calc(22px * var(--scale));
    height: calc(22px * var(--scale));
    min-width: calc(22px * var(--scale));
    min-height: calc(22px * var(--scale));
}
.settings-form-group .filter_checkboxes_list li label {
    font-size: calc(14px * var(--scale));
    line-height: calc(17px * var(--scale));
    background: transparent;
    padding: 0;
    padding: calc(10px * var(--scale)) 0;
    color: #BFD0D8;
}
textarea.input {
    border-radius: calc(16px * var(--scale));
    resize: vertical;
    min-height: calc(230px * var(--scale));
}
.settings-sidebar.help-desk-sidebar {
    max-width: calc(878px * var(--scale));;
    display: flex;
    flex-direction: column;
}
.help-desk-body {
    padding: calc(30px * var(--scale));
    border-bottom: 1px solid #07161C;
    flex: 1;
    max-height: calc(100vh - 236px);
    overflow-y: auto;
}
.messages-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.messages-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.message-user-info {
    display: flex;
    align-items: center;
}
.message-user-info h3 {
    font-weight: 400;
    font-size: calc(12px * var(--scale));
    line-height: calc(16px * var(--scale));
    color: #7C8DA0;
}
.message-user-info .avatar-wrpr {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    width: calc(36px * var(--scale));
    height: calc(36px * var(--scale));
    min-width: calc(36px * var(--scale));
    min-height: calc(36px * var(--scale));
    border-radius: 50%;
    overflow: hidden;
    margin-right: calc(30px * var(--scale));
}
.messages-right .message-user-info {
    flex-direction: row-reverse;
}
.messages-right .avatar-wrpr {
    margin-right: 0;
    margin-left: calc(30px * var(--scale));
}
.message-text {
    background: #07161C;
    border-radius: calc(20px * var(--scale));
    padding: calc(16px * var(--scale)) calc(20px * var(--scale));
    margin-left: calc(46px * var(--scale));
    max-width: calc(518px * var(--scale));
    font-weight: 400;
    font-size: calc(14px * var(--scale));
    line-height: calc(24px * var(--scale));
    color: #BFD0D8;
}
.messages-right .message-text {
    margin-right: calc(46px * var(--scale));
}
.help-desk-footer {
    padding: calc(30px * var(--scale));
}
.help-desk-footer .send-btn-wrpr .btn {
    width: initial;
    padding-left: calc(39px * var(--scale));
    padding-right: calc(39px * var(--scale));
}
.help-desk-footer textarea.input {
    min-height: calc(200px * var(--scale));
    font-size: calc(14px * var(--scale));
    line-height: calc(18px * var(--scale));
}
.help-desk-footer textarea.input::placeholder {
    color: #324457;
}
.help-desk-footer .input-label::before,
.show-in-mob,
.table-page-header-box-toggler{
    display: none;
}
.header a:hover {
    filter: brightness(1.3);
}
.signin-form_container {
    width: 100%;
}
.neuro-world-image-bg {
    position: absolute;
    left: calc(50% - 350px);
    top: calc(50% - 250px);
    height: 500px;
    width: 700px;
}
.main-neuro-world-image {
    z-index: 2;
}
.dashboard-main-screen {
    overflow: hidden;
}
.tph-cards-wrpr {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + calc(2.5px * var(--scale)));
    margin-left: calc(-2.5px * var(--scale));
}
.tph-cards-wrpr .table-page-header-box {
    margin: calc(2.5px * var(--scale));
}
.tph-cards-wrpr .table-page-header-box.w-100 {
    width: calc(100% - calc(5px * var(--scale)));
}
.tph-cards-wrpr .table-page-header-box.w-50 {
    width: calc(50% - calc(5px * var(--scale)));
}
.tph-cards-wrpr .table-page-header-box.w-25 {
    width: calc(25% - calc(5px * var(--scale)));
}
.table-page-header-box.p-0 {
    padding: 0;
}
.tph-cards-wrpr .col-50 {
    width: calc(50% - calc(5px * var(--scale)));
    display: flex;
    flex-wrap: wrap;
}
.map-box {
    position: relative;
    overflow: hidden;
    object-fit: cover;
    height: 100%;
    min-height: 230px;
}
/* .map-box img {
    max-width: initial;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
} */
.dashboard-content-product-page .map-box {
    min-height: 402px;
}
/* .map-box .map-Div.leaflet-container {
    mix-blend-mode: luminosity;
} */
.unlock-map {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: calc(16px * var(--scale));
    line-height: calc(19px * var(--scale));
    color: #506D8B;
    z-index: 2;
}
.unlock-map svg {
    width: calc(19px * var(--scale));
    height: calc(26px * var(--scale));
    margin-bottom: calc(4px * var(--scale));
}
.table-page-header-box.card-product-info-wrpr {
    padding: calc(50px * var(--scale)) calc(10px * var(--scale)) calc(10px * var(--scale));
    position: relative;
}
.card-product-info-header {
    padding-bottom: calc(9px * var(--scale));
    margin-bottom: calc(9px * var(--scale));
    border-bottom: 1px solid #1B485B;
    min-height: calc(90px * var(--scale));
}
.card-product-info-header h3 {
    font-weight: 700;
    font-size: calc(100px * var(--scale));
    line-height: calc(80px * var(--scale));
    color: #BFD0D8;
}
.card-product-info-header h3 .lock-icon {
    display: flex;
    align-items: center;
}
.card-product-info-header h3 .lock-icon svg {
    width: calc(50px * var(--scale));
    height: calc(80px * var(--scale));
}
.card-product-info-body h4 {
    font-weight: 700;
    font-size: calc(18px * var(--scale));
    line-height: calc(22px * var(--scale));
    color: #FFFFFF;
    margin-bottom: calc(10px * var(--scale));
}
.card-product-info-body p {
    font-weight: 400;
    font-size: calc(12px * var(--scale));
    line-height: calc(15px * var(--scale));
    color: #7C8DA0;
}
.lock-features-icon {
    display: flex;
    align-items: center;
}
.lock-features-icon svg {
    width: calc(25px * var(--scale));
    height: calc(32px * var(--scale));
}
.lock-features-icon.lock-card-top {
    position: absolute;
    right: calc(10px * var(--scale));
    top: calc(10px * var(--scale));
}
.card-product-info-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.simple-list-links {
    display: flex;
    align-items: center;
}
.simple-list-links li+li {
    margin-left: calc(16px * var(--scale));
}
.simple-list-links li a {
    font-weight: 400;
    font-size: calc(14px * var(--scale));
    line-height: calc(17px * var(--scale));
    color: #7C8DA0;
}
.simple-list-links li a.active {
    color: #00E0D1;
}
.dashboard-sidebar {
    position: relative;
}
.dashboard-header-toggle-sidebar {
    position: absolute;
    right: calc(-29px * var(--scale));
    top: 0;
    background: #081921;
    width: calc(24px * var(--scale));
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(55px * var(--scale));
    border-right: calc(5px * var(--scale)) solid #07161C;
    color: #506D8B;
}
.dashboard-header-toggle-sidebar:hover {
    color: #fff;
}
.dashboard-header-toggle-sidebar svg {
    width: calc(12px * var(--scale));
    height: calc(21px * var(--scale));
}
.dashboard-sidebar-overflow {
    overflow-y: auto;
    max-height: calc(100vh - calc(65px * var(--scale)));
}
.dashboard-header .filter-btns-group li>* {
    min-width: calc(130px * var(--scale));
    padding: calc(8px * var(--scale)) calc(10px * var(--scale));
    padding-left: calc(18px * var(--scale));
}
.dashboard-header .filter-btns-group li>* .remove-circle-btn {
    margin-left: auto;
}
.btn.export-to-csv-btn {
    display: inline-flex;
    align-items: center;
    width: fit-content;
    padding: calc(6px * var(--scale)) calc(18px * var(--scale));
}
.export-to-csv-btn svg {
    margin-left: 6px;
    margin-top: -2px;
    height: calc(14px * var(--scale));
    width: calc(10px * var(--scale));
}
.table-page-header-box.card-product-info-wrpr.pb-50 {
    padding-bottom: calc(50px * var(--scale));
}
.table-page-header-box h1.b-0 {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
}
.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    zoom: 0.5;
}
.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}
.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}
.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}
.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}
.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}
.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}
.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}
.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}
.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}
@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.min-h-50 {
    min-height: 50px;
}
.leaflet-safari .leaflet-tile-container {
    width: auto;
    height: auto;
}
.g_id_signin,
.g_id_signin>div,
.g_id_signin>div>div {
    width: 100%;
}
.g_id_signin>div>div>div {
    align-items: center;
    background-color: #07161c;
    border: 1px solid #324457;
    border-radius: calc(300px*var(--scale));
    color: #7c8da0;
    display: flex;
    font-size: calc(16px*var(--scale));
    justify-content: space-between;
    padding: calc(15px*var(--scale)) calc(16px*var(--scale));
    width: 100%;
}
.g_id_signin {
    margin-bottom: 30px;
}
.lock-item,
.lock-icon,
.lock-features-icon {
    cursor: pointer;
    transition: 0.15s all ease-in-out;
}
.lock-item:hover,
.lock-icon:hover,
.lock-features-icon:hover {
    opacity: 0.7;
}
.amount_circle_result.small-circle {
    position: absolute;
    right: -3px;
    top: -1px;
    min-width: calc(24px * var(--scale));
    min-height: calc(24px * var(--scale));
    font-size: calc(15px * var(--scale));
    line-height: calc(17px * var(--scale));
}
.ovh {
    overflow: hidden;
}
.map-fullscreen .tph-cards-wrpr,
.map-fullscreen .map-box {
    height: calc(100vh - calc(130px * var(--scale)));
}
.map-fullscreen .tph-cards-wrpr .table-page-header-box.w-50 {
    width: 100%;
}
.povered-by-neobi {
    position: absolute;
    right: 40px;
    bottom: 20px;
    z-index: 1000;
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 12px;
    pointer-events: none;
}
.povered-by-neobi svg {
    min-width: 50px;
    margin-left: 10px;
    margin-top: -6px;
}
.map-zoom-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 40px;
    top: 60px;
    z-index: 1000;
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
}
.map-zoom-block ul {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.map-zoom-block ul li {
    width: 4px;
    height: 4px;
    min-width: 4px;
    min-height: 4px;
    border-radius: 50%;
    background: #0b1f27;
    cursor: pointer;
}
.map-zoom-block ul li.active {
    width: 10px;
    height: 10px;
    min-width: 10px;
    min-height: 10px;
    background: #00E0D1;
}
.map-zoom-block ul li.active-prev-1,
.map-zoom-block ul li.active-next-1 {
    width: 6px;
    height: 6px;
    min-width: 6px;
    min-height: 6px;
}
.map-zoom-block ul li.active-prev-2,
.map-zoom-block ul li.active-next-2 {
    width: 4px;
    height: 4px;
    min-width: 4px;
    min-height: 4px;
}
.map-zoom-block ul li.sub-active {
    background: #324457;
}
.map-zoom-block ul li+li {
    margin-top: 4px;
}
.map-zoom-block a {
    color: #BFD0D8;
    display: flex;
    align-items: center;
    margin: 2px 0;
}
.map-zoom-block a:hover {
    color: #FFFFFF;
}
.sidebar-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
}
.sidebar-pagination li + li {
    margin-left: 15px;
}
.sidebar-pagination li a {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 26px;
    min-height: 26px;
    border-radius: 50%;
    font-size: 12px;
}
.sidebar-pagination li a.dots {
    pointer-events: none;
}
.sidebar-pagination li a.active {
    background: #00E0D1;
    color: #FFFFFF;
}
.vertical-margin-line {
    border: 1px solid #1B485B;
    width: 1px;
    height: calc(50px * var(--scale));
    margin: 0 calc(20px * var(--scale));
}
.forgot-password-link-wrpr {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
    padding-left: 20px;
    margin-top: 10px;
}
.forgot-password-link-wrpr a {
    color: #00E0D1;
    text-decoration: underline;
    font-size: 14px;
}
.forgot-password-link-wrpr a:hover {
    opacity: 0.7;
}
.pagination-btn-disabled {
    opacity: 0.5;
    cursor: no-drop;
}
.fullscreen-change-btn {
    position: absolute;
    right: 34px;
    top: 20px;
    background: #0C202B;
    border-radius: 8px;
    width: 28px;
    height: 28px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #BFD0D8;
    padding: 5px;
}
.fullscreen-change-btn:hover {
    color: #fff;
}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 200px; /* Adjust width as needed */
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Adjust position as needed */
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

/* Skeleton loader animation */
@keyframes shine {
    to {
      background-position-x: -200%;
    }
  }

.skeleton-loader {
    background: #eee ;
    background: linear-gradient(110deg, #2a3a4b 8%, #1c2a3b 18%, #2a3a4b 33%);
    border-radius: 99px !important;
    background-size: 200% 100% !important;
    animation: 1.5s shine linear infinite !important;
}

.skeleton-capsule {
    width: 200px !important; /* Adjust width as needed */
    height: 22px; /* Adjust height as needed */
    margin-right: 10px; /* Adjust margin as needed */
}

@keyframes shine {
    to {
      background-position-x: -200%;
    }
  }



  
  